import React from "react";

function GallerySection({arrDataLinkVidYt}) {
    //console.log(arrDataLinkVidYt)
  return (
    <>
    <a className="anchor-target" id="gallery-section"></a>
      {/* Gallery Section */}
      <div className="py-5" id="gallery">
        <div className="w-100 container">
          {/* <div className="">&nbsp;</div> */}
          <h4 className="news-prime-title" data-aos="zoom-in-down" data-aos-duration={500}>
          <i className="fa fa-lg fa-light fa-regular fa-images shadow-lg" style={{"color":"#fcf7d9 "}} ></i><span style={{"color":"#2a3d50"}}> Galeri</span>
          </h4>
          <div className="mb-3">&nbsp;</div>
          <div className="d-flex flex-column w-100 justify-content-center align-items-center">
            <div
              className="main-gallery"
              data-aos="fade-right"
              data-aos-duration={1500}
            >
              <iframe
                className="w-100 rounded-3 shadow-lg"
                height={500}
                src={arrDataLinkVidYt[0].attributes.link_embeded_yt}
                title="YouTube video player"
                allow="web-share"
                allowFullScreen=""
              />
            </div>
            <div
              className="scrollx-gallery d-flex flex-row gap-2"
              style={{ overflowX: "scroll" }}
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              {arrDataLinkVidYt.map(linkyt => (
                <iframe key={linkyt.id}
                type="text/html"
                className="rounded"
                width={250}
                height={200}
                src={linkyt.attributes.link_embeded_yt}
                title="YouTube video player"
                allow="web-share"
                allowFullScreen={true}
                />
              ))}
              
                
            </div>
          </div>
        </div>
      </div>
      {/* end of Gallery Section */}
    </>
  );
}

export default GallerySection;
